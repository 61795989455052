//export const serverURL = 'https://console.monespace.apavesuite.com/';
export const serverURL = 'https://pp.console.monespace.apavesuite.com/';
//export const serverURL = 'https://test.console.monespace.apavesuite.com/';
//export const serverURL = 'http://localhost:3000/';

export const userspaceURL = 'https://pp.espaceclient.apavesuite.com/v1/';
//export const userspaceURL = 'http://localhost:3000/v1/';


export const drupalURL = 'https://apave.fr/jsonapi/' // 'https://pp.apave.fr/jsonapi/'
export const trainingAPI = `${serverURL}api/`


export const privacyURL = `${serverURL}privacy`;
export const logoURL = `${serverURL}assets/logos/`;
export const apiURL = `${serverURL}v1/`;
export const profileURL = `${serverURL}profiles/`;

export const avertinonpremiumURL = `https://avertinone.premium.apavesuite.com/v1/`; //appid == 2000

export const ROLE_DUNS_CLIENT = 'dunsclient';
export const ROLE_APAVE_CLIENT = 'apaveclient';
export const ROLE_PROJECT_CLIENT = 'projectclient';
export const ROLE_ADMIN = 'admin';
export const ROLE_CLIENT = 'client';
export const ROLE_MANAGER = 'manager';
export const ROLE_USER = 'user';
export const USER_ROLES = [ROLE_CLIENT, ROLE_ADMIN, ROLE_MANAGER, ROLE_USER];

export const CATALOGUE_APAVE_TEAM = "6231c96b7fcdd8500ff28e49"; //"Former vos équipes avec Apave"
export const CATALOGUE_APAVE_TEAM_PUBLIC = "636a0056fcb10cda72dbbba5";
export const CATALOGUE_APAVE_TEAM_SERVICE = "63ff5c95a003c298ab34714b"; //"Vérifier votre conformité"
export const CATALOGUE_APAVE_TEAM_SERVICE_PUBLIC = "64004435fcb10cda72011ded";

export const GOOGLE_MAP_APIKEY = "AIzaSyCh8HgMOWp4MEnDz0wJdF1Coh_WA-y9aLY";

export const statues = ['Registered', 'Confirmed', 'Blocked'];
export const USER_STATUS_REGISTERED = statues[0];
export const USER_STATUS_CONFIRMED = statues[1];

export const MEMBERSHIPS = ['START', 'PREMIUM', 'EXCLUSIVE'];
export const MEMBERSHIP_START = 'START';
export const MEMBERSHIP_PREMIUM = 'PREMIUM';
export const MEMBERSHIP_EXCLUSIVE = 'EXCLUSIVE';

export const EXPORT_MODE_EXCEL = 'excel';
export const EXPORT_MODE_CSV = 'csv';

export const NO_CLIENT_ID = '0000';

export const PRODUCT_MODEL_FIELD_TYPES = [
    'TEXT',
    'NUMERIC',
    'DATE',
    'TIME',
    'DATE/TIME'
];

export const PRODUCT_MODEL_FIELD_TYPE_TEXT = PRODUCT_MODEL_FIELD_TYPES[0];
export const PRODUCT_MODEL_FIELD_TYPE_NUMERIC = PRODUCT_MODEL_FIELD_TYPES[1];
export const PRODUCT_MODEL_FIELD_TYPE_DATE = PRODUCT_MODEL_FIELD_TYPES[2];
export const PRODUCT_MODEL_FIELD_TYPE_TIME = PRODUCT_MODEL_FIELD_TYPES[3];
export const PRODUCT_MODEL_FIELD_TYPE_DATETIME = PRODUCT_MODEL_FIELD_TYPES[4];

export const PRODUCT_TYPES = [
    'training', //02 = Formations
    'services' //01 = Prestations
];
export const PRODUCT_TYPE_TRAINING = PRODUCT_TYPES[0];
export const PRODUCT_TYPE_SERVICES = PRODUCT_TYPES[1];


export const CATALOGUE_TYPE_NATIONAL = 'national' // Catalogue Contract National
export const CATALOGUE_TYPE_GENERAL = 'general' //Catalogue Générique
export const CATALOGUE_TYPE_ESSENTIAL = 'essential' //Catalogue Générique Essentiel
export const CATALOGUE_TYPE_PACK = 'pack'

export const CATALOGUE_TYPES = [
    CATALOGUE_TYPE_NATIONAL,
    CATALOGUE_TYPE_GENERAL,
    CATALOGUE_TYPE_ESSENTIAL,
    CATALOGUE_TYPE_PACK
];

export const INDUSTRY_VALUES = [
    'industrial-products-manufacturing', //Produits industriels et fabrication, 
    'energies', //Energies, 
    'infrastructure', //Infrastructure, 
    'construction', //Construction, 
    'transport', //Transport, 
    'food-industry', //Agroalimentaire, 
    'automotive', //Automobile, 
    'consumer-distribution', //Biens de consommation et distribution, 
    'health-medico-social', //Santé et Médico Social, 
    'public-services-local-communities', //Services publics et collectivités locales, 
    'telecommunications-digital', //Télécommunications et Digital, 
    'tertiary', //Tertiaire, 
    'small-businesses-SMEs-selfemployed-workers' //très petites entreprises/PME/travailleurs indépendants
];

export const YOURNEEDS = [
    'train-teams', //Train your teams
    'check-compliance', //Check your compliance
    'operate-renovate', // Build, operate and renovate safely
    'operate-complete', //Operate in complete safety
    'preserving-health', //Preserving health and safety at work
    'audit-certify', // Audit - Certify - Label
    'environmental', //Take environmental samples
    'test-qualify', //Test and qualify your products
]

export const PRODUCT_PROCESS_TYPES = [
    'intra',
    'inter'
];

export const PRODUCT_PROCESS_TYPE_INTRA = PRODUCT_PROCESS_TYPES[0];
export const PRODUCT_PROCESS_TYPE_INTER = PRODUCT_PROCESS_TYPES[1];

export const CURRENCIES = [
    'EUR',
    'USD',
    'GBP'
]
export const CUSTOM_FIELD_TYPE_TEXT = 'text';
export const CUSTOM_FIELD_TYPE_NUMERIC = 'numberic';
export const CUSTOM_FIELD_TYPE_CHECKBOX = 'checkbox';
export const CUSTOM_FIELD_TYPE_DROPDOWN_TEXT = 'dropdown_text';
export const CUSTOM_FIELD_TYPE_DROPDOWN_MULTI = 'dropdown_multi';
export const CUSTOM_FIELD_TYPE_CAPTCHA = 'captcha';

export const SITE_FIELD_TYPES = [
    CUSTOM_FIELD_TYPE_TEXT,
    CUSTOM_FIELD_TYPE_NUMERIC,
    CUSTOM_FIELD_TYPE_CHECKBOX,
    CUSTOM_FIELD_TYPE_DROPDOWN_TEXT,
    CUSTOM_FIELD_TYPE_DROPDOWN_MULTI,
    CUSTOM_FIELD_TYPE_CAPTCHA
];

export const LEAD_TYPES = [
    'hubspot',
    'chat',
    'userspaces',
    'guestspaces',
    'question'
]

export const LEAD_STATUSES = [
    'new',
    'ase',
    'apa',
    'als',
    'ano',
    'response_client',
    'transfer_agency',
    'close'
]

export const LEAD_EXTERNAL_STATUSES = [
    'new',
    'inprogress',
    'valid',
    'close'
]

export const LEAD_HUBSPOT_STATUSES = [
    '1', //NEW
    '2', //Waiting on contact
    '3', //Waiting on us
    '4' //Closed
]

export const PERIODICITIES = [
    ['AMI100', 'AMI102', 3],
    ['AMI102', 'AMI102', 3],
    ['AMI105', 'AMI107', 3],
    ['AMI107', 'AMI107', 3],
    ['AMI110', 'AMI112', 3],
    ['AMI112', 'AMI112', 3],
    ['AMI115', 'AMI117', 3],
    ['AMI117', 'AMI117', 3],
    ['AMI900', 'AMI102', 3],
    ['AMI902', 'AMI103', 3],
    ['AMI905', 'AMI107', 3],
    ['AMI915', 'AMI117', 3],
    ['AMR100', 'AMR112', 3],
    ['AMR105', 'AMR107', 3],
    ['AMR107', 'AMR107', 3],
    ['AMR110', 'AMR112', 3],
    ['AMR112', 'AMR112', 3],
    ['AMR112', 'AMR112', 3],
    ['APA020', 'APA225', 3],
    ['APA020', 'APA925', 3],
    ['APR021', 'APR215', 3],
    ['APR022', 'APR225', 3],
    ['APR023', 'APR023', 3],
    ['ATB001', 'ATB003', 3],
    ['ATB002', 'ATB003', 3],
    ['ATB003', 'ATB003', 3],
    ['ATB008', 'ATB003', 3],
    ['BAC081', 'BAC104', 5],
    ['BAC082', 'BAC104', 5],
    ['BAC083', 'BAC104', 5],
    ['BAC085', 'BAC104', 5],
    ['BAC086', 'BAC104', 5],
    ['BAC087', 'BAC087', 5],
    ['BAC091', 'BAC104', 5],
    ['BAE001', 'BAE001', 5],
    ['BAE002', 'BAE001', 5],
    ['BAE003', 'BAE008', 5],
    ['BAE004', 'BAE007', 5],
    ['BAE005', 'BAE008', 5],
    ['BAE006', 'BAE007', 5],
    ['BAE007', 'BAE007', 5],
    ['BAE008', 'BAE008', 5],
    ['ELB080', 'ELB180', 3],
    ['ELB081', 'ELB181', 3],
    ['ELB085', 'ELB185', 3],
    ['ELB090', 'ELB190', 3],
    ['ELB091', 'ELB190', 3],
    ['ELB092', 'ELB091', 3],
    ['ELB093', 'ELB193', 3],
    ['ELB094', 'ELB190', 3],
    ['ELB095', 'ELB195', 3],
    ['ELB096', 'ELB193', 3],
    ['ELB180', 'ELB180', 3],
    ['ELB181', 'ELB181', 3],
    ['ELB185', 'ELB185', 3],
    ['ELB190', 'ELB190', 3],
    ['ELB191', 'ELB191', 3],
    ['ELB193', 'ELB193', 3],
    ['ELB195', 'ELB195', 3],
    ['ELB880', 'ELB882', 3],
    ['ELB881', 'ELB883', 3],
    ['ELB882', 'ELB882', 3],
    ['ELB883', 'ELB883', 3],
    ['ELB890', 'ELB892', 3],
    ['ELB891', 'ELB893', 3],
    ['ELB892', 'ELB892', 3],
    ['ELB893', 'ELB893', 3],
    ['END001', 'END021', 1],
    ['ENT004', 'ENT006', 5],
    ['ENT012', 'ENT007', 3],
    ['FFT001', 'FFT002', 3],
    ['FFT002', 'FFT002', 3],
    ['LMC010', 'LMC015', 5],
    ['LMC011', 'LMC015', 5],
    ['LMC015', 'LMC015', 5],
    ['LMC110', 'LMC115', 5],
    ['LMC111', 'LMC115', 5],
    ['LMC113', 'LMC115', 5],
    ['LMC115', 'LMC115', 5],
    ['LMG030', 'LMG035', 5],
    ['LMG031', 'LMG035', 5],
    ['LMG035', 'LMG035', 5],
    ['LMG130', 'LMG135', 5],
    ['LMG131', 'LMG135', 5],
    ['LMG135', 'LMG135', 5],
    ['LMP010', 'LMP015', 5],
    ['LMP011', 'LMP015', 5],
    ['LMP015', 'LMP015', 5],
    ['LMP110', 'LMP115', 5],
    ['LMP111', 'LMP115', 5],
    ['LMP115', 'LMP115', 5],
    ['LMC012', 'LMC015', 5],
    ['LMC020', 'LMC025', 5],
    ['LMC021', 'LMC025', 5],
    ['LMC025', 'LMC025', 5],
    ['LMC120', 'LMC125', 5],
    ['LMC121', 'LMC125', 5],
    ['LMC125', 'LMC125', 5],
    ['LMG010', 'LMG015', 5],
    ['LMG011', 'LMG015', 5],
    ['LMG015', 'LMG015', 5],
    ['LMG020', 'LMG025', 5],
    ['LMG021', 'LMG025', 5],
    ['LMG025', 'LMG025', 5],
    ['LMG110', 'LMG115', 5],
    ['LMG111', 'LMG115', 5],
    ['LMG115', 'LMG115', 5],
    ['LMG120', 'LMG125', 5],
    ['LMG121', 'LMG125', 5],
    ['LMG125', 'LMG125', 5],
    ['LML020', 'LML025', 5],
    ['LML021', 'LML025', 5],
    ['LML025', 'LML025', 5],
    ['LML120', 'LML125', 5],
    ['LML121', 'LML125', 5],
    ['LML125', 'LML125', 5],
    ['LMP012', 'LMP015', 5],
    ['LMT030', 'LMT035', 10],
    ['LMT030', 'LMT035', 10],
    ['LMT035', 'LMT035', 10],
    ['LMT130', 'LMT135', 10],
    ['LMT131', 'LMT135', 10],
    ['LMT135', 'LMT135', 10],
    ['NR003', 'NUR011', 3],
    ['NUC001', 'NUC011', 4],
    ['NUC003', 'NUC803', 3],
    ['NUC011', 'NUC011', 4],
    ['NUC013', 'NUC803', 3],
    ['NUC803', 'NUC803', 3],
    ['NUC813', 'NUC803', 3],
    ['NUR001', 'NUR011', 3],
    ['NUR002', 'NUR012', 3],
    ['NUR003', 'NUR011', 3],
    ['NUR011', 'NUR011', 3],
    ['NUR012', 'NUR012', 3],
    ['NUR021', 'NUR023', 3],
    ['NUR023', 'NUR023', 3],
    ['NUR025', 'NUR023', 3],
    ['NUR025', 'NUR023', 3],
    ['NUR031', 'NUR033', 3],
    ['NUR033', 'NUR033', 3],
    ['NUR035', 'NUR033', 3],
    ['NUR035', 'NUR033', 3],
    ['NUR041', 'NUR043', 3],
    ['NUR043', 'NUR043', 3],
    ['NUR045', 'NUR043', 3],
    ['NUR045', 'NUR043', 3],
    ['NUS001', 'NUS011', 4],
    ['NUS002', 'NUS012', 4],
    ['NUS003', 'NUS003', 3],
    ['NUS011', 'NUS011', 4],
    ['NUS012', 'NUS012', 4],
    ['NUS013', 'NUS013', 4],
    ['NUS013', 'NUS013', 3],
    ['NUS023', 'NUS023', 4],
    ['NUS023', 'NUS023', 3],
    ['PIA011', 'PIA013', 3],
    ['PIA012', 'PIA013', 3],
    ['PIA013', 'PIA013', 3],
    ['PIA014', 'PIA013', 3],
    ['PIA021', 'PIA023', 3],
    ['PIA022', 'PIA023', 3],
    ['PIA023', 'PIA023', 3],
    ['PIA024', 'PIA023', 3],
    ['PIA031', 'PIA033', 3],
    ['PIA032', 'PIA033', 3],
    ['PIA033', 'PIA033', 3],
    ['PIA034', 'PIA033', 3],
    ['PRB001', 'PRB001', 3],
    ['PRB002', 'PRB002', 4],
    ['PRB029', 'PRB129', 3],
    ['PRB049', 'PRB049', 5],
    ['PRB060', 'PRB063', 3],
    ['PRB065', 'PRB065', 2],
    ['PRB066', 'PRB065', 2],
    ['PRB067', 'PRB068', 2],
    ['PRB068', 'PRB068', 2],
    ['PRB129', 'PRB129', 3],
    ['PRB201', 'PRB201', 3],
    ['PRB202', 'PRB202', 4],
    ['PRB210', 'PRB201', 3],
    ['PRB220', 'PRB202', 4],
    ['PRB501', 'PRB501', 3],
    ['PRB502', 'PRB502', 4],
    ['PRB503', 'PRB001', 3],
    ['PRB504', 'PRB001', 3],
    ['PRB505', 'PRB002', 4],
    ['PRB508', 'PRB002', 4],
    ['PRD002', 'PRD003', 2],
    ['PRD003', 'PRD003', 2],
    ['PRS001', 'PRS002', 2],
    ['PRS002', 'PRS002', 2],
    ['PRB032', 'AMI112', 3],
    ['PRB034', 'AMI112', 3],
    ['PRB035', 'AMI107', 3],
    ['PRB037', 'AMI107', 3],
    ['PRB038', 'AMI102', 3],
    ['PRB040', 'AMI102', 3],
    ['PRB050', 'AMI117', 3],
    ['PRB051', 'AMI117', 3],
    ['SDA001', 'SDA011', 3],
    ['SDA002', 'SDA012', 3],
    ['SDA003', 'SDA013', 3],
    ['SDA004', 'SDA014', 3],
    ['SDA006', 'SDA016', 3],
    ['SDA011', 'SDA011', 3],
    ['SDA012', 'SDA012', 3],
    ['SDA013', 'SDA013', 3],
    ['SDA014', 'SDA014', 3],
    ['SDA016', 'SDA016', 3],
    ['SDF001', 'SDF011', 2],
    ['SDF011', 'SDF011', 2],
    ['SDF012', 'SDF012', 3],
    ['SDP001', 'SDP001', 3],
    ['SPZ001', 'SPZ011', 5],
    ['SPZ011', 'SPZ011', 5],
    ['THH001', 'THH005', 3],
    ['THH003', 'THH005', 3],
    ['AMI100AD', 'AMI102AD', 3],
    ['AMI102AD', 'AMI102AD', 3],
    ['AMI105AD', 'AMI107AD', 3],
    ['AMI107AD', 'AMI107AD', 3],
    ['AMI110AD', 'AMI112AD', 3],
    ['AMI112AD', 'AMI112AD', 3],
    ['AMI115AD', 'AMI117AD', 3],
    ['AMI117AD', 'AMI117AD', 3],
    ['AMI900AD', 'AMI102AD', 3],
    ['AMI902AD', 'AMI103AD', 3],
    ['AMI905AD', 'AMI107AD', 3],
    ['AMI915AD', 'AMI117AD', 3],
    ['AMR100AD', 'AMR112AD', 3],
    ['AMR105AD', 'AMR107AD', 3],
    ['AMR107AD', 'AMR107AD', 3],
    ['AMR110AD', 'AMR112AD', 3],
    ['AMR112AD', 'AMR112AD', 3],
    ['AMR112AD', 'AMR112AD', 3],
    ['APA020AD', 'APA225AD', 3],
    ['APA020AD', 'APA925AD', 3],
    ['APR021AD', 'APR215AD', 3],
    ['APR022AD', 'APR225AD', 3],
    ['APR023AD', 'APR023AD', 3],
    ['ATB001AD', 'ATB003AD', 3],
    ['ATB002AD', 'ATB003AD', 3],
    ['ATB003AD', 'ATB003AD', 3],
    ['ATB008AD', 'ATB003AD', 3],
    ['BAC081AD', 'BAC104AD', 5],
    ['BAC082AD', 'BAC104AD', 5],
    ['BAC083AD', 'BAC104AD', 5],
    ['BAC085AD', 'BAC104AD', 5],
    ['BAC086AD', 'BAC104AD', 5],
    ['BAC087AD', 'BAC087AD', 5],
    ['BAC091AD', 'BAC104AD', 5],
    ['BAE001AD', 'BAE001AD', 5],
    ['BAE002AD', 'BAE001AD', 5],
    ['BAE003AD', 'BAE008AD', 5],
    ['BAE004AD', 'BAE007AD', 5],
    ['BAE005AD', 'BAE008AD', 5],
    ['BAE006AD', 'BAE007AD', 5],
    ['BAE007AD', 'BAE007AD', 5],
    ['BAE008AD', 'BAE008AD', 5],
    ['ELB080AD', 'ELB180AD', 3],
    ['ELB081AD', 'ELB181AD', 3],
    ['ELB085AD', 'ELB185AD', 3],
    ['ELB090AD', 'ELB190AD', 3],
    ['ELB091AD', 'ELB190AD', 3],
    ['ELB092AD', 'ELB091AD', 3],
    ['ELB093AD', 'ELB193AD', 3],
    ['ELB094AD', 'ELB190AD', 3],
    ['ELB095AD', 'ELB195AD', 3],
    ['ELB096AD', 'ELB193AD', 3],
    ['ELB180AD', 'ELB180AD', 3],
    ['ELB181AD', 'ELB181AD', 3],
    ['ELB185AD', 'ELB185AD', 3],
    ['ELB190AD', 'ELB190AD', 3],
    ['ELB191AD', 'ELB191AD', 3],
    ['ELB193AD', 'ELB193AD', 3],
    ['ELB195AD', 'ELB195AD', 3],
    ['ELB880AD', 'ELB882AD', 3],
    ['ELB881AD', 'ELB883AD', 3],
    ['ELB882AD', 'ELB882AD', 3],
    ['ELB883AD', 'ELB883AD', 3],
    ['ELB890AD', 'ELB892AD', 3],
    ['ELB891AD', 'ELB893AD', 3],
    ['ELB892AD', 'ELB892AD', 3],
    ['ELB893AD', 'ELB893AD', 3],
    ['END001AD', 'END021AD', 1],
    ['ENT004AD', 'ENT006AD', 5],
    ['ENT012AD', 'ENT007AD', 3],
    ['FFT001AD', 'FFT002AD', 3],
    ['FFT002AD', 'FFT002AD', 3],
    ['LMC010AD', 'LMC015AD', 5],
    ['LMC011AD', 'LMC015AD', 5],
    ['LMC015AD', 'LMC015AD', 5],
    ['LMC110AD', 'LMC115AD', 5],
    ['LMC111AD', 'LMC115AD', 5],
    ['LMC113AD', 'LMC115AD', 5],
    ['LMC115AD', 'LMC115AD', 5],
    ['LMG030AD', 'LMG035AD', 5],
    ['LMG031AD', 'LMG035AD', 5],
    ['LMG035AD', 'LMG035AD', 5],
    ['LMG130AD', 'LMG135AD', 5],
    ['LMG131AD', 'LMG135AD', 5],
    ['LMG135AD', 'LMG135AD', 5],
    ['LMP010AD', 'LMP015AD', 5],
    ['LMP011AD', 'LMP015AD', 5],
    ['LMP015AD', 'LMP015AD', 5],
    ['LMP110AD', 'LMP115AD', 5],
    ['LMP111AD', 'LMP115AD', 5],
    ['LMP115AD', 'LMP115AD', 5],
    ['LMC012AD', 'LMC015AD', 5],
    ['LMC020AD', 'LMC025AD', 5],
    ['LMC021AD', 'LMC025AD', 5],
    ['LMC025AD', 'LMC025AD', 5],
    ['LMC120AD', 'LMC125AD', 5],
    ['LMC121AD', 'LMC125AD', 5],
    ['LMC125AD', 'LMC125AD', 5],
    ['LMG010AD', 'LMG015AD', 5],
    ['LMG011AD', 'LMG015AD', 5],
    ['LMG015AD', 'LMG015AD', 5],
    ['LMG020AD', 'LMG025AD', 5],
    ['LMG021AD', 'LMG025AD', 5],
    ['LMG025AD', 'LMG025AD', 5],
    ['LMG110AD', 'LMG115AD', 5],
    ['LMG111AD', 'LMG115AD', 5],
    ['LMG115AD', 'LMG115AD', 5],
    ['LMG120AD', 'LMG125AD', 5],
    ['LMG121AD', 'LMG125AD', 5],
    ['LMG125AD', 'LMG125AD', 5],
    ['LML020AD', 'LML025AD', 5],
    ['LML021AD', 'LML025AD', 5],
    ['LML025AD', 'LML025AD', 5],
    ['LML120AD', 'LML125AD', 5],
    ['LML121AD', 'LML125AD', 5],
    ['LML125AD', 'LML125AD', 5],
    ['LMP012AD', 'LMP015AD', 5],
    ['LMT030AD', 'LMT035AD', 10],
    ['LMT030AD', 'LMT035AD', 10],
    ['LMT035AD', 'LMT035AD', 10],
    ['LMT130AD', 'LMT135AD', 10],
    ['LMT131AD', 'LMT135AD', 10],
    ['LMT135AD', 'LMT135AD', 10],
    ['NR003AD', 'NUR011AD', 3],
    ['NUC001AD', 'NUC011AD', 4],
    ['NUC003AD', 'NUC803AD', 3],
    ['NUC011AD', 'NUC011AD', 4],
    ['NUC013AD', 'NUC803AD', 3],
    ['NUC803AD', 'NUC803AD', 3],
    ['NUC813AD', 'NUC803AD', 3],
    ['NUR001AD', 'NUR011AD', 3],
    ['NUR002AD', 'NUR012AD', 3],
    ['NUR003AD', 'NUR011AD', 3],
    ['NUR011AD', 'NUR011AD', 3],
    ['NUR012AD', 'NUR012AD', 3],
    ['NUR021AD', 'NUR023AD', 3],
    ['NUR023AD', 'NUR023AD', 3],
    ['NUR025AD', 'NUR023AD', 3],
    ['NUR025AD', 'NUR023AD', 3],
    ['NUR031AD', 'NUR033AD', 3],
    ['NUR033AD', 'NUR033AD', 3],
    ['NUR035AD', 'NUR033AD', 3],
    ['NUR035AD', 'NUR033AD', 3],
    ['NUR041AD', 'NUR043AD', 3],
    ['NUR043AD', 'NUR043AD', 3],
    ['NUR045AD', 'NUR043AD', 3],
    ['NUR045AD', 'NUR043AD', 3],
    ['NUS001AD', 'NUS011AD', 4],
    ['NUS002AD', 'NUS012AD', 4],
    ['NUS003AD', 'NUS003AD', 3],
    ['NUS011AD', 'NUS011AD', 4],
    ['NUS012AD', 'NUS012AD', 4],
    ['NUS013AD', 'NUS013AD', 4],
    ['NUS013AD', 'NUS013AD', 3],
    ['NUS023AD', 'NUS023AD', 4],
    ['NUS023AD', 'NUS023AD', 3],
    ['PIA011AD', 'PIA013AD', 3],
    ['PIA012AD', 'PIA013AD', 3],
    ['PIA013AD', 'PIA013AD', 3],
    ['PIA014AD', 'PIA013AD', 3],
    ['PIA021AD', 'PIA023AD', 3],
    ['PIA022AD', 'PIA023AD', 3],
    ['PIA023AD', 'PIA023AD', 3],
    ['PIA024AD', 'PIA023AD', 3],
    ['PIA031AD', 'PIA033AD', 3],
    ['PIA032AD', 'PIA033AD', 3],
    ['PIA033AD', 'PIA033AD', 3],
    ['PIA034AD', 'PIA033AD', 3],
    ['PRB001AD', 'PRB001AD', 3],
    ['PRB002AD', 'PRB002AD', 4],
    ['PRB029AD', 'PRB129AD', 3],
    ['PRB049AD', 'PRB049AD', 5],
    ['PRB060AD', 'PRB063AD', 3],
    ['PRB065AD', 'PRB065AD', 2],
    ['PRB066AD', 'PRB065AD', 2],
    ['PRB067AD', 'PRB068AD', 2],
    ['PRB068AD', 'PRB068AD', 2],
    ['PRB129AD', 'PRB129AD', 3],
    ['PRB201AD', 'PRB201AD', 3],
    ['PRB202AD', 'PRB202AD', 4],
    ['PRB210AD', 'PRB201AD', 3],
    ['PRB220AD', 'PRB202AD', 4],
    ['PRB501AD', 'PRB501AD', 3],
    ['PRB502AD', 'PRB502AD', 4],
    ['PRB503AD', 'PRB001AD', 3],
    ['PRB504AD', 'PRB001AD', 3],
    ['PRB505AD', 'PRB002AD', 4],
    ['PRB508AD', 'PRB002AD', 4],
    ['PRD002AD', 'PRD003AD', 2],
    ['PRD003AD', 'PRD003AD', 2],
    ['PRS001AD', 'PRS002AD', 2],
    ['PRS002AD', 'PRS002AD', 2],
    ['PRB032AD', 'AMI112AD', 3],
    ['PRB034AD', 'AMI112AD', 3],
    ['PRB035AD', 'AMI107AD', 3],
    ['PRB037AD', 'AMI107AD', 3],
    ['PRB038AD', 'AMI102AD', 3],
    ['PRB040AD', 'AMI102AD', 3],
    ['PRB050AD', 'AMI117AD', 3],
    ['PRB051AD', 'AMI117AD', 3],
    ['SDA001AD', 'SDA011AD', 3],
    ['SDA002AD', 'SDA012AD', 3],
    ['SDA003AD', 'SDA013AD', 3],
    ['SDA004AD', 'SDA014AD', 3],
    ['SDA006AD', 'SDA016AD', 3],
    ['SDA011AD', 'SDA011AD', 3],
    ['SDA012AD', 'SDA012AD', 3],
    ['SDA013AD', 'SDA013AD', 3],
    ['SDA014AD', 'SDA014AD', 3],
    ['SDA016AD', 'SDA016AD', 3],
    ['SDF001AD', 'SDF011AD', 2],
    ['SDF011AD', 'SDF011AD', 2],
    ['SDF012AD', 'SDF012AD', 3],
    ['SDP001AD', 'SDP001AD', 3],
    ['SPZ001AD', 'SPZ011AD', 5],
    ['SPZ011AD', 'SPZ011AD', 5],
    ['THH001AD', 'THH005AD', 3],
    ['THH003AD', 'THH005AD', 3],
]
export const DEPARTMENTS = [
    { label: "Ain", id: "01" },
    { label: "Aisne", id: "02" },
    { label: "Allier", id: "03" },
    { label: "Alpes-de-Haute-Provence", id: "04" },
    { label: "Hautes-Alpes", id: "05" },
    { label: "Alpes-Maritimes", id: "06" },
    { label: "Ardèche", id: "07" },
    { label: "Ardennes", id: "08" },
    { label: "Ariège", id: "09" },
    { label: "Aube", id: "10" },
    { label: "Aude", id: "11" },
    { label: "Aveyron", id: "12" },
    { label: "Bouches-du-Rhône", id: "13" },
    { label: "Calvados", id: "14" },
    { label: "Cantal", id: "15" },
    { label: "Charente", id: "16" },
    { label: "Charente-Maritime", id: "17" },
    { label: "Cher", id: "18" },
    { label: "Corrèze", id: "19" },
    { label: "Corse-du-Sud", id: "2A" },
    { label: "Haute-Corse", id: "2B" },
    { label: "Côte-d'Or", id: "21" },
    { label: "Côtes-d'Armor", id: "22" },
    { label: "Creuse", id: "23" },
    { label: "Dordogne", id: "24" },
    { label: "Doubs", id: "25" },
    { label: "Drôme", id: "26" },
    { label: "Eure", id: "27" },
    { label: "Eure-et-Loir", id: "28" },
    { label: "Finistère", id: "29" },
    { label: "Gard", id: "30" },
    { label: "Haute-Garonne", id: "31" },
    { label: "Gers", id: "32" },
    { label: "Gironde", id: "33" },
    { label: "Hérault", id: "34" },
    { label: "Ille-et-Vilaine", id: "35" },
    { label: "Indre", id: "36" },
    { label: "Indre-et-Loire", id: "37" },
    { label: "Isère", id: "38" },
    { label: "Jura", id: "39" },
    { label: "Landes", id: "40" },
    { label: "Loir-et-Cher", id: "41" },
    { label: "Loire", id: "42" },
    { label: "Haute-Loire", id: "43" },
    { label: "Loire-Atlantique", id: "44" },
    { label: "Loiret", id: "45" },
    { label: "Lot", id: "46" },
    { label: "Lot-et-Garonne", id: "47" },
    { label: "Lozère", id: "48" },
    { label: "Maine-et-Loire", id: "49" },
    { label: "Manche", id: "50" },
    { label: "Marne", id: "51" },
    { label: "Haute-Marne", id: "52" },
    { label: "Mayenne", id: "53" },
    { label: "Meurthe-et-Moselle", id: "54" },
    { label: "Meuse", id: "55" },
    { label: "Morbihan", id: "56" },
    { label: "Moselle", id: "57" },
    { label: "Nièvre", id: "58" },
    { label: "Nord", id: "59" },
    { label: "Oise", id: "60" },
    { label: "Orne", id: "61" },
    { label: "Pas-de-Calais", id: "62" },
    { label: "Puy-de-Dôme", id: "63" },
    { label: "Pyrénées-Atlantiques", id: "64" },
    { label: "Hautes-Pyrénées", id: "65" },
    { label: "Pyrénées-Orientales", id: "66" },
    { label: "Bas-Rhin", id: "67" },
    { label: "Haut-Rhin", id: "68" },
    { label: "Rhône", id: "69" },
    { label: "Haute-Saône", id: "70" },
    { label: "Saône-et-Loire", id: "71" },
    { label: "Sarthe", id: "72" },
    { label: "Savoie", id: "73" },
    { label: "Haute-Savoie", id: "74" },
    { label: "Paris", id: "75" },
    { label: "Seine-Maritime", id: "76" },
    { label: "Seine-et-Marne", id: "77" },
    { label: "Yvelines", id: "78" },
    { label: "Deux-Sèvres", id: "79" },
    { label: "Somme", id: "80" },
    { label: "Tarn", id: "81" },
    { label: "Tarn-et-Garonne", id: "82" },
    { label: "Var", id: "83" },
    { label: "Vaucluse", id: "84" },
    { label: "Vendée", id: "85" },
    { label: "Vienne", id: "86" },
    { label: "Haute-Vienne", id: "87" },
    { label: "Vosges", id: "88" },
    { label: "Yonne", id: "89" },
    { label: "Territoire de Belfort", id: "90" },
    { label: "Essonne", id: "91" },
    { label: "Hauts-de-Seine", id: "92" },
    { label: "Seine-St-Denis", id: "93" },
    { label: "Val-de-Marne", id: "94" },
    { label: "Val-D'Oise", id: "95" },
    { label: "Guadeloupe", id: "971" },
    { label: "Martinique", id: "972" },
    { label: "Guyane", id: "973" },
    { label: "La Réunion", id: "974" },
    { label: "Mayotte", id: "976" },
]
export const REGIONS = [
    { label: "Ile-de-France", id: "001", tax: 1.1 },
    { label: "Nouvelle-Aquitaine", id: "002" },
    { label: "Guadeloupe", id: "003", tax: 1.15 },
    { label: "Martinique", id: "004", tax: 1.15 },
    { label: "Guyane", id: "005", tax: 1.15 },
    { label: "La Réunion", id: "006", tax: 1.15 },
    { label: "Mayotte", id: "007" },
    { label: "DROM-COM", id: "008" },
    { label: "Bretagne", id: "009" },
    { label: "Pays de la Loire", id: "010" },
    { label: "Hauts-de-France", id: "011" },
    { label: "Corse", id: "012" },
    { label: "Provence-Alpes-Côte d'Azur", id: "013" },
    { label: "Normandie", id: "014" },
    { label: "Grand Est", id: "015" },
    { label: "Bourgogne-Franche-Comté", id: "016" },
    { label: "Auvergne-Rhône-Alpes", id: "017" },
    { label: "Occitanie", id: "018" },
    { label: "Centre-Val de Loire", id: "019" }
]